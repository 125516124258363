import React, { useState } from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import "../Contact/style.css"

type FormEventTargetType = {
  target: {
    name: string
    value: string
  }
}

function encode(data: Record<string, string>) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  reCAPTCHAキーが登録されていません
  `)
}

const ContactForm: React.FC = () => {
  const [name, setName] = useState("")
  const [nameEvent, setNameEvent] = useState(false)
  const [email, setEmail] = useState("")
  const [emailEvent, setEmailEvent] = useState(false)
  const [affiliation, setAffiliation] = useState("")
  const [message, setMessage] = useState("")
  const [messageEvent, setMessageEvent] = useState(false)
  const recaptchaRef = React.createRef<Recaptcha>()
  const [recaptchaStatus, setRecaptchaStatus] = React.useState(false)
  const handleChange = (event: FormEventTargetType) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value)
        break
      case "email":
        setEmail(event.target.value)
        break
      case "affiliation":
        setAffiliation(event.target.value)
        break
      case "message":
        setMessage(event.target.value)
        break
      default:
    }
  }
  const canSubmit = () => {
    if (name === "") return false
    if (email === "") return false
    if (affiliation === "") return false
    if (message === "") return false
    return false
  }

  const recaptchaSuccess = () => {
    setRecaptchaStatus(true)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget
    const temp = recaptchaRef.current?.getValue()
    const recaptchaValue: string = temp ? temp : ""
    const formName = form.getAttribute("name")
    const action = form.getAttribute("action")
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formName ? formName : "",
        "g-recaptcha-response": recaptchaValue,
        name,
        email,
        affiliation,
        message
      })
    })
      .then(() => navigate(action ? action : ""))
      .catch(error => alert(error))
  }

  return (
    <div className="form-wrapper">
      <form
        name="d-capital-contact"
        method="POST"
        action="/contact/success"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="d-capital-contact" />
        <p>
          <label>
            <input
              className={
                nameEvent && !name
                  ? "disable-content-input"
                  : "form-content-input"
              }
              type="text"
              placeholder="お名前(必須)"
              color="black"
              name="name"
              onClick={() => setNameEvent(true)}
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            <input
              className="form-content-input"
              type="text"
              placeholder="ご所属"
              name="affiliation"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            <input
              type="text"
              className={
                emailEvent && !email
                  ? "disable-content-input"
                  : "form-content-input"
              }
              onClick={() => setEmailEvent(true)}
              placeholder="メールアドレス(必須)"
              name="email"
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            <textarea
              className={
                messageEvent && !message
                  ? "disable-content-text-area"
                  : "form-content-text-area"
              }
              onClick={() => setMessageEvent(true)}
              name="message"
              placeholder="メッセージ(必須)"
              onChange={handleChange}
            />
          </label>
        </p>
        <div className="recaptcha-wrapper">
          <Recaptcha
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={recaptchaSuccess}
          />
        </div>
        <div className={"submit-button-wrapper"}>
          <button
            className="submit-button"
            type="submit"
            disabled={!canSubmit() && !recaptchaStatus}
          >
            送信する
          </button>
        </div>
        <div className={"privacy-policy-text-wrapper"}>
          <p className="privacy-policy-text">
            送信により、
            <a href="/policy" target="_blank" rel="noopener noreferrer">
              個人情報の取扱いについて
            </a>
            同意したものとみなします 。
          </p>
        </div>
      </form>
    </div>
  )
}
export default ContactForm
